
$famNavHeight: 40px;
$themeColor: #7CBD0A;

$logoFontSize: 2em;

.mynav-container {
    background-image: repeating-linear-gradient(to right,#DDD 0,#DDD 50%,transparent 50%,transparent 100%);
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: 8px 2px;
    display: block;
}

.bg-mynav-container{
    position: fixed;
    width: 100%;
    height: 99px;
    background-color: #fff; 
    z-index: -2;
}

.nav-logo-scaled-down{
    transform: scale(.8);
}

.row{
    #nav-logo{
        justify-content: center;
        align-items: center;
        display: flex;

        #nav-logo-image{
            height: 100px;
            transition: transform .20s ease,-webkit-transform .20s ease;
            transform: scale(.9);
        }
    }

    #nav-search-icon{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        #social-icons{
            @media(max-width: 700px){
                display: none;
            }
            display: flex;
            align-items: center;
            justify-content: flex-end;
            #nav-facebook{
                font-size: $logoFontSize;
                color: rgb(10, 94, 189);
                margin-right: 15px;
            }
            #nav-instagram{
                font-size: $logoFontSize;
                color: rgb(209, 71, 83);
                margin-right: 15px;
            }
            #nav-pinterest{
                font-size: $logoFontSize;
                color: rgb(212, 18, 34);
                margin-right: 15px;
            }
        }
       

        #nav-burger-icon-font{
            font-size: $logoFontSize;
            color: $themeColor
        }

        #nav-burger-icon-font:hover{
            color: lighten($themeColor, 10);
            cursor: pointer;
        }
    }

    #nav-burger-icon{
        display: flex;
        align-items: center;

        #nav-burger-icon-font{
            font-size: $logoFontSize;
            color: $themeColor;
        }

        #nav-burger-icon-font:hover{
            color: lighten($themeColor, 10);
            cursor: pointer;
        }
    }
}
#burger-open{
    background-color: #fff;
    width: 100%;
    max-height: 500px;
    position: fixed;
    display: none;
    overflow: auto;
}

#searchInput{
    padding: 1em;
    font-size: 1rem;
    width: 100%;
}

.searchBtn{
    padding: 1em;
    margin-left: 15px;
}
.searchContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:1em;
}

.searchContainer-result{
    display: inline;
    align-items: center;
    justify-content: center;
    padding:1em;
}

.notfound{
    color: red;
}