$themeColor: #7CBD0A;
$recipeColor: darken(#F1C8AB, 30);
$cockTailColor: darken(#ECE1BE, 30);
$regionColor: darken(#AEDAD2, 30);
$ingredientColor: darken(#DBCFE3, 30);
$occasionColor: darken(#CFD9EB, 30);
$profileColor: darken(#EBD2C3, 30);
$storeColor: darken(#D4DCB4, 30);

.recipe-colors-fa{
    color: $recipeColor
}
.cocktails-colors-fa{
    color: $cockTailColor
}
.by-region-colors-fa{
    color: $regionColor;
}
.ingredients-colors-fa{
    color: $ingredientColor
}
.occasions-colors-fa{
    color: $occasionColor
}
.profile-colors-fa{
    color: $profileColor
}
.store-colors-fa{
    color: $storeColor
}

.main-image{
    width: 100%;
    min-height: 370px;
    max-height: 500px;
}

.top{
    padding: 60px;
    margin-top:30px;
    background-color: #fff;

    @media (max-width:1230px){
        padding:60px 0px 0px 0px;
    }

}

.main-title{
    display: flex;
    margin-top: -60px;
    justify-content: center;
    
    .main-title-sub{
        // width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        height: 46px;
        padding:10px;
        a{
            color: #333 !important;
            font-size: 1.25rem !important;
        }
    }

    
}
.card-pos-relative{
    position: relative !important;
    top: 0px !important;
    align-items: center;
}
.card__tag-wrapper-main{
    @media(max-width:990px){
        top: -.95rem !important;
    }
}
.triangle-wrapper{
    cursor: pointer;       
}
.card__tag-wrapper{
    position: absolute;
    top: -.95rem;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    @media(max-width:990px){
        top: 27px;
    }
// recipe
    #card__triangle-recipe{
        border-color: transparent $recipeColor transparent transparent;
    }
    #card__tag-recipe{
        background: $recipeColor;
    }
    #card__triangle--flipped-recipe{
        border-color: transparent transparent transparent $recipeColor;
    }
// cocktail
    #card__triangle-cocktail{
        border-color: transparent $cockTailColor transparent transparent;
    }
    #card__tag-cocktail{
        background: $cockTailColor;
    }
    #card__triangle--flipped-cocktail{
        border-color: transparent transparent transparent $cockTailColor;
    }
// region
    #card__triangle-region{
        border-color: transparent $regionColor transparent transparent;
    }
    #card__tag-region{
        background: $regionColor;
    }
    #card__triangle--flipped-region{
        border-color: transparent transparent transparent $regionColor;
    }
// ingredients
    #card__triangle-in{
        border-color: transparent $ingredientColor transparent transparent;
    }
    #card__tag-in{
        background: $ingredientColor;
    }
    #card__triangle--flipped-in{
        border-color: transparent transparent transparent $ingredientColor;
    }
// occasions
    #card__triangle-oc{
        border-color: transparent $occasionColor transparent transparent;
    }
    #card__tag-oc{
        background: $occasionColor;
    }
    #card__triangle--flipped-oc{
        border-color: transparent transparent transparent $occasionColor;
    }
// profile
    #card__triangle-pr{
        border-color: transparent $profileColor transparent transparent;
    }
    #card__tag-pr{
        background: $profileColor;
    }
    #card__triangle--flipped-pr{
        border-color: transparent transparent transparent $profileColor;
    }
// store
    #card__triangle-st{
        border-color: transparent $storeColor transparent transparent;
    }
    #card__tag-st{
        background: $storeColor;
    }
    #card__triangle--flipped-st{
        border-color: transparent transparent transparent $storeColor;
    }

    .card__triangle, .card__triangle--flipped{
        display: inline-block;
        width: 0;
        border-style: solid;
        border-width: 14px 14px 14px 0;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        border-color: transparent $themeColor transparent transparent;
    }

    .card__tag{
        display: inline-block;
        position: relative;
        padding: .47rem 0;
        vertical-align: top;
        font-family: "Work Sans",Arial,sans-serif;
        font-size: .75rem;
        line-height: 1.1;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: -webkit-calc(100% - 28px);
        max-width: calc(100% - 28px);
        color: #fff;
        background: $themeColor;
    }

    .card__triangle--flipped {
        border-width: 14px 0 14px 14px;
        border-color: transparent transparent transparent $themeColor;
    }
}

.side-image{
    width: 100%;
    height: 280px;
}

.side-card-container{
    position: static;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    padding: 30px;

    h4 {
        a {
            color: #333;
            font-size: 1.25rem !important;
        }
    }
}

.side-card{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: -15px;
    text-align: center;
    color: #333;
    background: #FFF;
    font-size: 1rem;
    border: 1px solid #EEE;
    box-shadow: 1px 1px 6px 0 rgba(0,0,0,.09);
    -webkit-transition: -webkit-transform .25s ease;
    transition: -webkit-transform .25s ease;
    transition: transform .25s ease;
    transition: transform .25s ease,-webkit-transform .25s ease;
    height: 103%;

    @media (max-width: 1230px){
        height: 95%;
    }

    @media (max-width: 990px){
        margin-top: 40px;
    }

}

.explore-container{
    display: flex;
    justify-content: center;   
    margin-top: 50px;
    color: #000;
}

.explore-container-title{
    font-size: 1.25rem;
}

.side-icons{
    font-size: 2em;
    display: flex;
    justify-content: center;
    padding: 15px;
    align-content: center;
}

.explore-container-sub{
    display: flex;
    justify-content: center;
    width: 100%;
    
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.bg-background-color{
    background: linear-gradient(to bottom,#FFF 5rem,rgb(233, 235, 232) 3rem)!important;
    min-height: 730px;
    @media (max-width: 1230px) {
        min-height: 760px;
    }
}

.ml-5{
    @media(max-width: 575px) {
        margin-left: 5px !important;
    }
}

.mt-3{
    @media(max-width: 575px) {
        margin-top: -15px !important;
    }
}

.mypaddingtop{
    @media(max-width: 480px){
        padding-top: 50px;
      }
}