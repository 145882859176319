.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-header{
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: transform .15s ease,-webkit-transform .15s ease;
}

.pt-190{
  @media(max-width: 480px){
    padding-top: 100px;
  }
  padding-top: 190px;
}

.container{
  max-width: 1280px !important;
}

img{
  cursor: pointer;
}

.animate-top{
  cursor: pointer;
  transition: transform .25s ease,-webkit-transform .25s ease;
  a.mini-icons{
    text-decoration: none;
  }
}
.animate-top:hover{
  transform: translateY(-5px);
  transition: transform .25s ease,-webkit-transform .25s ease;
}

.image-fitness{
  background-image: url('/img/fitness.png');
  background-color: #ff7f50;
  background-blend-mode: multiply;
}

.htmlHandler{
  width: 100%;
  border-width: 0px !important;
}