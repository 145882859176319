.main-container {
    min-height: -webkit-calc(100% - 162px);
    min-height: -moz-calc(100% - 162px);
    min-height: calc(100% - 162px);
    padding: 30px 0;
}
.mobile-filter-bar {
    border: solid 1px #ddd;
    height: 44px;
    line-height: 44px;
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    background-color: #fafafa;
    border-top: none; }
    .mobile-filter-bar > ul > li {
      border-right: solid 1px #ddd;
      padding-right: 15px;
      padding-left: 15px;
      text-transform: uppercase; }
      .mobile-filter-bar > ul > li:first-child {
        padding-left: 0; }
  
  @media (min-width: 68px) {
    .mobile-filter-sidebar {
      left: 0 !important; }
    .mobile-filter-bar {
      display: none; }
    .closeFilter {
      display: none; } }
  
  @media (max-width: 767px) {
    .jobs-s-tag {
      margin-bottom: 4px; }
    .hide-xs {
      display: none; }
    .btn-block-sm {
      display: block;
      width: 100%; }
    .closeFilter {
      cursor: pointer;
      display: block;
      font-size: 16px;
      height: 30px;
      line-height: 30px;
      padding: 3px;
      position: absolute;
      right: 10px;
      text-align: center;
      top: 7px;
      width: 30px;
      z-index: 2147483647; }
    .menu-overly-mask {
      background-color: #000;
      height: 100%;
      left: 0;
      opacity: 0.3;
      position: fixed;
      top: 0;
      -webkit-transition: all 0.2s linear 0s;
         -moz-transition: all 0.2s linear 0s;
           -o-transition: all 0.2s linear 0s;
              transition: all 0.2s linear 0s;
      visibility: hidden;
      -webkit-backface-visibility: hidden;
         -moz-backface-visibility: hidden;
              backface-visibility: hidden;
      width: 100%;
      z-index: 1100; }
      .menu-overly-mask.is-visible {
        cursor: pointer;
        opacity: 0.5;
        visibility: visible;
        -webkit-backface-visibility: visible;
           -moz-backface-visibility: visible;
                backface-visibility: visible; }
    .mobile-filter-sidebar .inner-box {
      margin-bottom: 0;
      border-radius: 0 !important; }
    .page-sidebar.mobile-filter-sidebar {
      height: 100%;
      overflow: auto;
      padding: 0;
      position: fixed;
      display: none;
      top: 0;
      width: 250px;
      z-index: 1110;
      left: -251px; } }

.sidebar-modern-inner {
    background: #fff;
    border: solid 1px #ddd;
}
.sidebar-modern-inner .block-title {
    padding: 15px 15px;
    border-bottom: solid 1px #ddd;
    position: relative;
}

.sidebar-modern-inner .block-title.has-arrow {
    margin-bottom: 5px;
}

.sidebar-modern-inner .block-title h5 a {
    color: #292b2c;
}

a{
    color: #7CBD0A;
    outline: 0;
    cursor: pointer;
    -webkit-transition: all 0.2s linear 0s, letter-spacing 0s linear 0s;
    -moz-transition: all 0.2s linear 0s, letter-spacing 0s linear 0s;
    -o-transition: all 0.2s linear 0s, letter-spacing 0s linear 0s;
    transition: all 0.2s linear 0s, letter-spacing 0s linear 0s;
}

.list-filter {
    width: 100%;
    display: block;
    clear: both;
}

.sidebar-modern-inner .block-content {
    padding: 15px 15px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.adds-wrapper {
    background: #fff;
    clear: both;
    height: auto;
    width: 100%;
}

.no-margin {
    margin: 0 !important;
}

.col-thin-left {
    padding-left: 10px;
}

.category-list {
    background: #ffffff;
    border-radius: 3px 3px;
    display: block;
    box-shadow: 0 1px 1px rgba(180, 180, 180, 0.5);
}
.item-list {
    border-bottom: solid 1px #ddd;
    clear: both;
    padding: 15px;
    height: auto;
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    -moz-transition: background 0.25s ease-in;
    -o-transition: background 0.25s ease-in;
    transition: background 0.25s ease-in;
    -webkit-transition: background 0.25s ease-in;
}

.item-list .row {
    margin: 0;
}

.no-padding {
    padding: 0 !important;
}

.make-grid .item-list .photobox, .make-grid .item-list .add-desc-box, .make-grid .item-list .price-box {
    width: 100%;
    clear: both;
    display: block !important;
    -webkit-flex: 100%;
    -moz-box-flex: 100%;
    flex: 100%;
    max-width: 100%;
}

.make-grid .item-list .add-desc-box {
    padding: 10px 0;
}

@media (max-width: 1199px) {
    .make-compact .item-list .add-title, .make-compact .item-list .info-row {
      width: 100%; }
    .ads-details {
      padding: 0; } 
}

.make-grid .item-list .price-box {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
}

.make-compact .item-list .photobox, .make-compact .item-list .info-row .category {
    display: none; }
  
  .make-compact .item-list .add-title {
    float: left;
    clear: left;
    height: 19px;
    overflow: hidden;
    padding-bottom: 7px;
    width: 60%; }
  
  .make-compact .item-list .info-row {
    display: block;
    float: left;
    clear: none;
    font-size: 12px;
    width: 40%; }
  
  .make-compact .item-list h2.item-price {
    line-height: 20px; }
  
  @media (max-width: 1199px) {
    .make-compact .item-list .add-title, .make-compact .item-list .info-row {
      width: 100%; }
    .ads-details {
      padding: 0; } }
  
  .category-list.make-compact .item-list .item-price {
    float: left;
    font-size: 20px;
    line-height: normal;
    margin-bottom: 0;
    padding-bottom: 0; }
  
  .category-list.make-compact .item-list .make-favorite span {
    font-size: 0;
    -webkit-transition: all 0.3s ease 0s;
       -moz-transition: all 0.3s ease 0s;
         -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
    width: 0; }
  
  .category-list.make-list .item-list, .category-list.make-compact .item-list {
    height: auto !important; }
  
  .category-list.make-grid .item-list {
    width: 50%;
    float: left;
    align-content: center;
    clear: none;
    border-right: solid 1px #ddd; }
    @media (max-width: 767px) {
      .category-list.make-grid .item-list {
        width: 100%; } 
    }
    .category-list.make-grid .item-list .make-favorite {
      margin-bottom: 5px; }

.add-image{
  margin-left:auto;
  margin-right: auto;
  display: block;
  width: 50%;
}

.add-title{
  text-align: center;
}