$themeColor: #7CBD0A;
$logoWidth: 80px;
$logoHeight: 30px;
$mobileScreenWidth: 700px;

@mixin mobile {
    @media (max-width: #{$mobileScreenWidth}) {
        @content;
    }
}

.fam-nav{
    background-color: #F2F5F8;
    padding-bottom: 0;
    -webkit-box-orient: horizontal;
    flex-direction: row;
    width: 100%;
    z-index: 13;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile{
        display: none;
    }

    .fam-title{
        color: darken($themeColor, 10);
        font-weight: 500;
        margin-right: 1rem;
    }

    .fam-logo{
        width: $logoWidth;
        height: $logoHeight;
        border: 1px solid darken($themeColor, 10);
        margin-left: 5px;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fam-logo:hover{
        background-color: #fff;
        cursor: pointer;
    }
}