.q_container {
    .tips-image{
        width: 350px;
        float: left;
    }

     .tips-section{
        display: inline;
     }

     .r{
        float: right;
     }
 
}